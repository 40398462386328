import React from 'react';
import { Button } from "antd";
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

import "./home.css"
import Logo from "./Logo";

const Footer = ({ isLandscape, tooWide }) => {

  return (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', position: 'relative', margin: window.innerWidth > 800 ? '200px auto auto 0' : '140px auto auto 0', padding: 20 }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 20, justifyContent: 'flex-end', padding: '40px 60px', height: 360, backgroundColor: '#1E2537', width: '100%', maxWidth: 1200, borderRadius: 60, marginBottom: window.innerWidth > 800 ? 40 : 0 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Logo width={46} height={46} />
          <div style={{ display: 'flex', color: 'white', gap: 30, alignItems: 'end' }}>
            <a href="https://app.replyreach.quest/signin" target='__blank' style={{ color: 'white', textDecoration: 'none' }}><div>Signup</div></a>
            <a href="https://app.replyreach.quest/pricing" target='__blank' style={{ color: 'white', textDecoration: 'none' }}><div>Pricing</div></a>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', color: 'white' }}>
          <div>© 2024 ReplyReach. Made by Resz Zahid @reszahid</div>
          <div>Twitter</div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: window.innerWidth > 800 ? 'row' : 'column',  gap: window.innerWidth > 800 ? 40 : 10, padding: window.innerWidth > 500 ? '60px 80px' : '40px', justifyContent: 'center', alignItems: 'center', marginTop: -140, height: 300, backgroundColor: '#4d28d2', width: 'calc(100% - 120px)', maxWidth: 900, borderRadius: 80, marginBottom: 40, position: 'absolute' }}>
        <div style={{ color: 'white', fontSize: window.innerWidth > 800 ? 38 : window.innerWidth > 500 ? 30 : 24, fontWeight: 600, textAlign: 'left' }}>Start targeting high-impact posts for your product today.</div>
        {
          window.innerWidth > 500 &&
          <a href="https://app.replyreach.quest/signin" target='__blank' style={{ color: 'white', textDecoration: 'none' }}>
            <Button style={{ display: 'flex', margin: '20px auto', width: 200, height: 50, alignItems: 'center' }} size='large'><div style={{ fontSize: 20, fontWeight: 500, width: '100%', textAlign: 'center' }}>Try ReplyReach</div></Button>
          </a>
        }
      </div>
    </div>
  )
}

const Hero = ({ isLandscape, tooWide }) => {

  return (
    <>
      <div style={{ backgroundColor: '#4d28d2', width: '100%', left: 0, height: (isLandscape && window.innerWidth < 1200) ? '100%' : window.innerWidth > 800 ? '70vh' : '80vh', position: 'absolute' }} />
      <div className="hero" style={{ position: 'relative', backgroundColor: tooWide ? '#4d28d2' : undefined  }}>
        <div className="hero-1st-child" style={{ position: 'relative', display: 'flex', flexDirection: 'column', height: isLandscape ? undefined : '80vh', justifyContent: 'flex-end' }}>
          <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
            <span className="text-gradient" style={{ fontSize: 20, fontWeight: 900 }}><Logo width={80} height={80} /></span>
          </div>
          <h1 style={{ fontSize: window.innerWidth > 800 ? 52 : 36 }}>
            Use AI to find and analyze social media posts where your product deserves a shoutout in the replies
          </h1>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
            <img style={{ boxShadow: "rgba(0, 0, 0, 0.2) 1px 2px 6px 0px", borderRadius: 10 }} alt="" src="./hero.png" />
          </div>
        </div>
      </div>
    </>
  )
}

const slideDesc = {
  1: { title: "Project Details", desc: "Fill in your project details", img: "./create_project.png" },
  2: { title: "Search & Save", desc: "Begin your search using AI suggestions or your own keywords, and save your findings to a bucket", img: "./search.png" },
  3: { title: "Analyze Results", desc: "Review your search results and start your analysis", img: "./analyze.png" },
  4: { title: "Automate Searches (Optional)", desc: "Automate your searches for the latest posts using the scheduler", img: "./scheduler.png" },
}

const HowItWorks = ({ isLandscape, tooWide }) => {
  const [currentSlide, setCurrentSlide] = React.useState(1)

  return (
    <div style={{ maxWidth: 900, margin: 'auto', padding: 20, marginTop: 40, marginBottom: 20 }}>
      <h1 style={{ fontSize: window.innerWidth > 800 ? 52 : 36 }}>
        How it works
      </h1>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
        <div style={{ display: 'flex', gap: 10, justifyContent: 'space-between', alignItems: 'center', flexDirection: window.innerWidth > 580 ? 'row' : 'column'  }}>
          <div style={{ display: 'flex', alignItems: 'baseline', gap: 15, alignSelf: 'start' }}>
            <div
              style={{
                border: '1px solid lightgrey',
                width: window.innerWidth > 500 ? 50: 40,
                height: window.innerWidth > 500 ? 50: 40,
                borderRadius: '100%',
                textAlign: 'center',
                fontSize: window.innerWidth > 500 ? 28 : 22,
                padding: 6,
                minWidth: 40,
              }}
            >
              {currentSlide}
            </div>
            <div>
              <h3 style={{ fontSize: window.innerWidth > 500 ? 26 : 24 }}>{slideDesc[currentSlide].title}</h3>
              <p>{slideDesc[currentSlide].desc}</p>
            </div>
          </div>
          <div style={{ display: 'flex', gap: 5, alignSelf: 'end' }}>
            <Button icon={<LeftOutlined />} onClick={() => { currentSlide > 1 && setCurrentSlide(currentSlide - 1) }} />
            <Button icon={<RightOutlined />} onClick={() => { currentSlide < 4 && setCurrentSlide(currentSlide + 1) }} />
          </div>
        </div>
        <a href={slideDesc[currentSlide].img}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <img style={{ boxShadow: "rgba(0, 0, 0, 0.2) 1px 2px 6px 0px", borderRadius: 10 }} alt="" src={slideDesc[currentSlide].img} />
          </div>
        </a>
      </div>
    </div>
  )
}

const Home = () => {
  const isLandscape = window.innerWidth > window.innerHeight;
  const tooWide = window.innerWidth < 1200 && window.innerWidth / window.innerHeight > 2;
  return (
    <div>
      <Hero isLandscape={isLandscape} tooWide={tooWide} />
      <HowItWorks isLandscape={isLandscape} tooWide={tooWide} />
      <Footer isLandscape={isLandscape} tooWide={tooWide} />
	  </div>
  );
}

export default Home;